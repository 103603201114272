import { RenameSection, ShopDelSection, SortSection } from "@/api/GoShopApi";
import { ShopAddSection } from "@/api/GoShopApi";
import { SectionType } from "@/common/enum";
import { TranslateProSource } from "@/common/formatter";
import { Section } from "@/services/Section/Section";
import SectionService from "@/services/Section/SectionService";
import ShopService from "@/services/Shop/ShopService";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
export default function SectionList() {
    const dialogVisible = ref(false);
    const isEdit = ref(false);
    const selectShop = ShopService.GetCurrentShop();
    const selectSection = reactive(new Section());
    const sections = reactive(new Array());
    const initSections = async () => {
        const res = await SectionService.GetAllSectionsByShopId(selectShop.id || 0);
        sections.splice(0, sections.length);
        for (let i = 0; i < res.length; i++) {
            sections.push(res[i]);
        }
    };
    initSections();
    const formatSource = (row) => {
        return TranslateProSource(row.type);
    };
    const columns = reactive([
        { label: '频道名称', prop: "name", width: 200 },
    ]);
    const onEnd = (args) => {
        if (args.oldIndex == args.newIndex) {
            return;
        }
        const toBefore = args.oldIndex > args.newIndex;
        if (toBefore) {
            const temp = sections[args.oldIndex];
            for (let i = args.oldIndex; i > args.newIndex; i--) {
                sections.splice(i, 1, sections[i - 1]);
            }
            sections.splice(args.newIndex, 1, temp);
        }
        else {
            const temp = sections[args.oldIndex];
            for (let i = args.oldIndex; i < args.newIndex; i++) {
                sections.splice(i, 1, sections[i + 1]);
            }
            sections.splice(args.newIndex, 1, temp);
        }
        let index = sections.length;
        const idArrs = [];
        sections.forEach(x => {
            x.sortWeight = index;
            index = index - 1;
            idArrs.push(x.id || 0);
        });
        SortSection({ merchantStoreId: selectShop.id, sectionIds: idArrs }).then(res => {
            if (res.isSuccess) {
                ElMessage.success("排序成功");
            }
        });
    };
    // 添加频道
    const addChannel = (val) => {
        dialogVisible.value = true;
        isEdit.value = false;
        selectSection.id = val.id;
        selectSection.name = val.name;
    };
    // 删除
    const dele = async (item) => {
        await ElMessageBox.confirm('确定删除该频道？', '删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        });
        const res = await ShopDelSection({ id: item.id });
        if (res.isSuccess) {
            ElMessage.success("删除成功");
            await SectionService.RefreshSectionsByShopId(selectShop.id || 0);
            initSections();
        }
    };
    // 重命名
    const rename = (val) => {
        isEdit.value = true;
        dialogVisible.value = true;
        selectSection.id = val.id;
        selectSection.name = val.name;
    };
    // 添加、编辑频道
    const submit = async () => {
        if (isEdit.value) {
            await RenameSection({ id: selectSection.id, name: selectSection.name });
        }
        else {
            await ShopAddSection({
                merchantStoreId: selectShop.id,
                name: selectSection.name,
                sortWeight: sections.length + 1,
                type: SectionType.Custom
            });
        }
        await SectionService.RefreshSectionsByShopId(selectShop.id || 0);
        initSections();
        dialogVisible.value = false;
    };
    return {
        sections,
        onEnd,
        columns,
        addChannel,
        dialogVisible,
        dele,
        rename,
        submit,
        selectSection
    };
}
