import { GetSectionList } from "@/api/GoShopApi";
import { reactive } from "vue";
export default class SectionService {
    static async GetAllSectionsByShopId(shopId) {
        const result = this.channels.get(shopId);
        if (result) {
            return result;
        }
        const res = await GetSectionList({ merchantStoreId: shopId });
        if (res.isSuccess) {
            this.channels.set(shopId, res.resultData);
            return res.resultData;
        }
        return [];
    }
    static async RefreshSectionsByShopId(shopId) {
        this.channels.delete(shopId);
        const res = await GetSectionList({ merchantStoreId: shopId });
        if (res.isSuccess) {
            this.channels.set(shopId, res.resultData);
        }
    }
}
SectionService.channels = reactive(new Map());
